.bg {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: -10;
  background: url("../../../img/landingpage.jpg");
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  z-index: -2;
  filter: brightness(60%);
  height: 90%;
  background-color: "#333333";
}

#logo {
  z-index: 1000;
  height: 493;
  width: 500;
  max-height: 170px;
  max-width: 200px;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .bg {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: -10;
    background: url("../../../img/landingpage.jpg");
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
    z-index: -2;
    filter: brightness(60%);
    height: 90%;
    background-color: black;
  }
  .logo {
    z-index: 1000;
    height: 30%;
    width: 35%;
    max-height: 170px;
    max-width: 200px;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .bg {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: -10;
    background: url("../../../img/landingpage.jpg");
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
    z-index: -2;
    filter: brightness(60%);
    height: 90%;
  }
  .logo {
    z-index: 1000;
    height: 30%;
    width: 35%;
    max-height: 170px;
    max-width: 200px;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .bg {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: -10;
    background: url("../../../img/landingpage.jpg");
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
    z-index: -2;
    filter: brightness(60%);
    height: 90%;
  }
  .logo {
    z-index: 1000;
    height: 20%;
    width: 25%;
    max-height: 170px;
    max-width: 200px;
  }
}

/*Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .bg {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: -10;
    background: url("../../../img/landingpage.jpg");
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
    z-index: -2;
    filter: brightness(60%);
    height: 90%;
  }
  .logo {
    z-index: 1000;
    height: 20%;
    width: 25%;
    max-height: 100px;
    max-width: 200px;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up)  */
@media only screen and (min-width: 1200px) {
  .bg {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: -10;
    background: url("../../../img/landingpage.jpg");
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
    z-index: -2;
    filter: brightness(60%);
    height: 90%;
  }
  .logo {
    z-index: 1000;
    height: 20%;
    width: 25%;
    max-height: 100px;
    max-width: 200px;
  }
}
